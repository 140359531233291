import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import "./Home.css";
import { TbTargetArrow } from "react-icons/tb";
import { GiCutDiamond } from "react-icons/gi";
import { IoEyeSharp } from "react-icons/io5";
import client from "../../Common/Client/Client";
import Slider from "react-slick";
import Icons from "../../Common/Icons/Icons";
import toast from "react-hot-toast";
import Header from "../../Common/Layout/Header/Header";
import Footer from "../../Common/Layout/Footer/Footer";

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2, // Show 2 reviews on medium screens
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1, // Show 1 review on small screens
      },
    },
  ],
};
const Home = () => {
  const [url, setUrl] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [activeCourseIndex, setActiveCourseIndex] = useState(0); // Show the first course by default
  const [blogs, setBlogs] = useState([]);
  const [course, setCourse] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getReview();
    getBlogs();
    fetchCourse();
    getQuotes();
    getlinks();
  }, []);
  const getlinks = async () => {
    try {
      const response = await client.get("/gallery/getyoutubeurl");
      if (response.status === 200) {
        setUrl(response.data.slice(0, 3));
      }
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  };

  const getQuotes = async () => {
    try {
      const response = await client.get("/quotes/getquotes", {
        withCredentials: true,
      });
      if (response.status === 200) {
        setQuotes(response.data);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to fetch quotes details");
      }
    }
  };

  const handleCourseClick = (index) => {
    setActiveCourseIndex(index); // Set the clicked course as active
  };
  const truncateDescription = (desc) => {
    const fullText = desc.join(" ");
    return fullText.split(" ").slice(0, 20).join(" ") + "...";
  };

  const getBlogs = async () => {
    try {
      const response = await client.get("/blog/get");
      if (response.status === 200) {
        const blogs = response.data;
        setBlogs(blogs.slice(-3));
      } else {
        toast.error("Error occuring in fetching data");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCourse = async () => {
    try {
      const response = await client.get("/course/getcourse");
      if (response.status === 200) {
        setCourse(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getReview = async () => {
    try {
      const response = await client.get("/review/getreview");
      if (response.status === 200) {
        setReviews(response.data);
        console.log(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getEmbedUrl = (videoUrl) => {
    console.log(videoUrl);
    if (typeof videoUrl !== "string") {
      return ""; // Return an empty string if videoUrl is not a string
    }

    const videoIdMatch = videoUrl.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    if (videoIdMatch) {
      const videoId = videoIdMatch[1];
      return `https://www.youtube.com/embed/${videoId}`;
    }

    return ""; // Return an empty string if no video ID is found
  };
  return (
    <Fragment>
      <Header />
      <Icons />
      <Carousel interval={3000} slide={false}  data-aos="fade-up">
        {/* Slide 1 */}
        <Carousel.Item>
          <div
            className="image-carasole hero-wrap home-image animated-img "
            style={{
              backgroundImage: "url('assets/images/home1.jpg')",
              backgroundAttachment: "fixed",
              backgroundPosition:"top center"
            }}
          >
            <div className="overlay"></div>
            <div
              className="row no-gutters slider-text slider-texts align-items-center justify-content-center"
              
            >
              <div
                className="col-md-8 text-center car-image animated-caption"
                style={{
                  width: "80%",
                }}
              >
                <h1 className="display-4 mb-4 car" >
                  Healthcare-specific English language proficiency exam.
                </h1>
                <p className="mt-auto">
                  <button
                    onClick={() => {
                      navigate("/contact", {
                        state: { targetId: "contact" },
                      });
                    }}
                    className="btn btn-primary   mr-3"
                  >
                    Reach Out
                  </button>
                  <Link to="/course" className="btn btn-secondary ">
                    Learn More
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>

        {/* Slide 2 */}
        <Carousel.Item>
          <div
            className="image-carasole hero-wrap pte-image animated-img"
            style={{
              backgroundImage: "url('assets/images/home2.jpg')",
              backgroundAttachment: "fixed",
               backgroundPosition:"top center"
            }}
          >
            <div className="overlay"></div>
            <div
              className="row no-gutters slider-text slider-texts align-items-center justify-content-center"
             
            >
              <div
                className="col-md-8  text-center car-image animated-caption"
                style={{
                  width: "80%",
                }}
              >
                <h1 className="mb-4 car ">
                  Computer-based English language proficiency exam.
                </h1>
                <p className="mt-auto">
                  <button
                    onClick={() => {
                      navigate("/contact", {
                        state: { targetId: "contact" },
                      });
                    }}
                    className="btn btn-primary   mr-3"
                  >
                    Reach Out
                  </button>
                  <Link to="/course" className="btn btn-secondary">
                    Learn More
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>

        {/* Slide 3 */}
        <Carousel.Item>
          <div
            className="image-carasole hero-wrap osce-image animated-img"
            style={{
              backgroundImage: "url('assets/images/home3.jpg')",
              backgroundAttachment: "fixed",
               backgroundPosition:"top center"
            }}
          >
            <div className="overlay"></div>
            <div
              className="row no-gutters slider-text slider-texts image-home align-items-center justify-content-center"
             
            >
              <div
                className="col-md-8 text-center car-image animated-caption"
                style={{
                  width: "80%",
                }}
              >
                <h1 className="mb-4 car">
                  Practical exam assessing clinical healthcare skills.
                </h1>
                <p className="mt-auto">
                  <button
                    onClick={() => {
                      navigate("/contact", {
                        state: { targetId: "contact" },
                      });
                    }}
                    className="btn btn-primary   mr-3"
                  >
                    Reach Out
                  </button>
                  <Link to="/course" className="btn btn-secondary">
                    Learn More
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <section className="section-home">
        <div className="container">
          <div className="row service-home">
            <div className="col-md-4">
              <div className="main mains">
                <div className="service">
                  <div className="service-logo">
                    <TbTargetArrow className="service-icons" />
                  </div>
                  <h4>Our Mission</h4>
                  <p>
                    The vision of our OET coaching center Thiruvananthapuram is to be a global leader in
                    preparing healthcare professionals for success in their
                    careers. We strive to provide the highest quality OET
                    preparation courses that not only equip students with the
                    language proficiency needed to excel but also empower them
                    to deliver exceptional care and communication in healthcare
                    settings worldwide.
                  </p>
                </div>
                <div className="shadowOne"></div>
                <div className="shadowTwo"></div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="main mains">
                <div className="service">
                  <div className="service-logo">
                    <IoEyeSharp className="service-icons" />
                  </div>
                  <h4>Our Vision</h4>
                  <p>
                    Our vision is to set the global standard in training
                    healthcare professionals by offering premier OET preparation
                    courses. These courses not only enhance essential language
                    skills but also empower students to deliver exceptional care
                    and effective communication in diverse healthcare settings
                    worldwide, ensuring they succeed in their careers with
                    confidence and excellence.
                  </p>
                </div>
                <div className="shadowOne"></div>
                <div className="shadowTwo"></div>
              </div>
            </div>
            <div className="col-md-4 mdddd">
              <div className="main ma ">
                <div className="service">
                  <div className="service-logo">
                    <GiCutDiamond className="service-icons" />
                  </div>
                  <h4>Our Values</h4>
                  <p>
                    The values of our OET institute include excellence,
                    integrity, inclusivity, and innovation. We are dedicated to
                    creating a supportive learning environment that fosters
                    professional growth, cultural sensitivity, and ethical
                    conduct. Our commitment ensures each student receives
                    tailored preparation, empowering them to achieve their
                    academic and professional goals confidently.
                  </p>
                </div>
                <div className="shadowOne"></div>
                <div className="shadowTwo"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="ftco-section-3 img"
        style={{
          backgroundImage: "url(assets/images/traini.png)",
          backgroundPosition: "top center",
        }}
      >
        <div
          className="overlay"
          style={{
            background: "linear-gradient(to right, #6a00ff 0%, #ffd900 100%)",
          }}
        />
        <div className="container">
          <div className="row d-md-flex justify-content-center">
            <div className="col-md-9 about-video text-center">
              <h2 className=" " data-aos="fade-up">
                Dream Live, the best training center in Kerala, ensures your success.
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section
        className="ftco-section"
        style={{
          padding: "7em 0px 0px 0px",
        }}
        data-aos="fade-up"
      >
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 heading-section   text-center">
              <h2 className="mb-4">Our Videos</h2>
            </div>
          </div>
          <div className="row videos_row">
            {url.map((item, index) => (
              <div key={index} className="col-lg-4 mb-sm-4 " data-aos="fade-up">
                <div className="video-container">
                  <iframe
                    width="100%"
                    height="200"
                    src={getEmbedUrl(item.videoUrl)}
                    title={`YouTube video ${index}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section
        className="ftco-section"
        style={{
          padding: "7em 0px 0px 0px",
        }}
        data-aos="fade-up"
      >
        <div className="container">
          <div className="row justify-content-center  pb-3">
            <div className="col-md-7 heading-section   text-center">
              <h2 className="">Our Courses</h2>
            </div>
          </div>
        </div>
        <section className="ftco-section kk" data-aos="fade-up">
          <div className="container">
            <div
              className="row"
              style={{
                justifyContent: "center",
                rowGap:"30px"
              }}
            >
              {course.map((item, index) => {
                return (
                  <div class="course_card">
                    <div class="card__body">
                      <p class="card__title">{item.courseName}</p>
                      <p class="card__paragraph">
                      {Array.isArray(item.aboutCourse)
  ? item.aboutCourse
      .join(" ")
      .slice(0, 170) + (item.aboutCourse.join(" ").length > 170 ? "..." : "")
  : item.aboutCourse.slice(0, 170) + (item.aboutCourse.length > 170 ? "..." : "")
}
                      </p>
                      <div class="button-container">
                        <button
                          onClick={() => {
                            navigate("/course-details", {
                              state: {
                                courseDetails: item,
                                targetId: "contact-details",
                              },
                            });
                          }}
                          class="btn btn-primary"
                        >
                          Enroll Now!
                        </button>
                        <button
                          onClick={() => {
                            navigate("/course-details", {
                              state: { courseDetails: item },
                            });
                          }}
                          class="btn btn-secondary"
                        >
                          Learn More!
                        </button>
                      </div>
                    </div>

                    <div class="card__ribbon">
                      <label class="card__ribbon-label">{index + 1}</label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </section>
      <section
        className="ftco-section"
        style={{ padding: "4em 0px 0px 0px" }}
        data-aos="fade-up"
      >
        <div className="container">
          <div className="row justify-content-center pb-3">
            <div className="col-md-7 heading-section text-center">
              <h2 className="mb-4">Course Plan and Package</h2>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-12 text-center">
              <div className="course-names-container">
                {course.map((course, index) => (
                  <span
                    key={course._id}
                    className={`course-name ${
                      activeCourseIndex === index ? "active" : ""
                    }`}
                    onClick={() => handleCourseClick(index)}
                  >
                    {course.courseName}
                  </span>
                ))}
              </div>
            </div>
          </div>

          <div className="row">
            {course.length > 0 && (
              <div
                className={`col-md-12 ${
                  course[activeCourseIndex].package.length === 1
                    ? "single-package"
                    : ""
                }`}
              >
                <div className="package-container">
                  {course[activeCourseIndex].package.map((pkg) => (
                    <div key={pkg._id} className="package-home-details">
                      <h4 className="package-title">{pkg.packageName}</h4>
                      <h1 className="package-price">
                        <span>₹</span>
                        {pkg.packagePrice}
                      </h1>
                      <ul className="package-features">
                        <li className="feature-item">
                          <span className="icons green-icon">
                            <i className="fas fa-check-circle"></i>
                          </span>
                          <span
                            style={{ textAlign: "left", marginLeft: "5px" }}
                          >
                            Package Duration {pkg.packageDuration}
                          </span>
                        </li>
                        {pkg.keyFeatures.slice(0, 2).map((feature, fIndex) => (
                          <li key={fIndex} className="feature-item">
                            <span className="icons green-icon">
                              <i className="fas fa-check-circle"></i>
                            </span>
                            <span
                              style={{ textAlign: "left", marginLeft: "5px" }}
                            >
                              {feature}
                            </span>
                          </li>
                        ))}
                      </ul>
                      <button
                        className="package-button"
                        onClick={() => {
                          navigate("/course-details", {
                            state: {
                              courseDetails: course[activeCourseIndex],
                              targetId: "package",
                            },
                          });
                        }}
                      >
                        Read More
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="ftco-freeTrial" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <div className="free-trial  ">
                  <h3>Book Your Spot Today!</h3>
                  <p>
              Dream Live is the top coaching institute in Thiruvananthapuram, guiding you towards excellence and global opportunities.
                  </p>
                </div>
                <div className="btn-join  ">
                  <p>
                    <button
                      onClick={() => {
                        navigate("/contact", {
                          state: { targetId: "contact" },
                        });
                      }}
                      className="btn btn-primary   mr-3"
                    >
                      Join Now!
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="ftco-section bg-light" data-aos="fade-up">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 heading-section text-center">
              <h2 className="mb-4">Our Testimonials</h2>
            </div>
          </div>

          <div className="slider-container">
            <Slider {...settings}>
              {reviews.map((value) => {
                const {
                  name,
                  review: reviewText,
                  rating,
                  image,
                  createdAt,
                } = value;
                return (
                  <div class="review-card">
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/368px-Google_2015_logo.svg.png"
                      alt="Google logo"
                      className="google-logo"
                    />
                    <div className="review-header">
                      <img src={image} alt={name} className="review-image" />
                      <p className="review-name"> {name}</p>
                    </div>
                    <p className="review-text">{reviewText}</p>

                    <div className="rating">
                      {"★".repeat(rating)}
                      {"☆".repeat(5 - rating)}
                    </div>

                    <p className="review-date">
                      {" "}
                      {new Date(createdAt).toLocaleDateString()}
                    </p>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </section>

      <Carousel fade slide={false} data-aos="fade-up">
        {quotes.map((quote, index) => {
          return (
            <Carousel.Item>
              <section
                className="ftco-section-3 img quotes_image"
                style={{
                  backgroundImage: `url(${quote.image})`,
                  padding: "13em 0px",
                }}
              >
                <div
                  className="overlay"
                  style={{
                    background:
                      "linear-gradient(to right, #6a00ff 0%, #ffd900 100%)",
                  }}
                />
                <div className="container quotes-container">
                  <div className="row d-md-flex justify-content-center">
                    <div className="col-md-9 about-video text-center ">
                      {/* Quote */}
                      <p
                        className="give"
                        style={{
                          color: "#fff",

                          padding: "0px 40px",
                        }}
                      >
                        "{quote.quotes}"
                      </p>

                      {/* Author Name */}
                      <p
                        className="give1"
                        style={{
                          color: "#fff",
                          fontStyle: "italic",
                        }}
                      >
                        –{quote.name}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </Carousel.Item>
          );
        })}
      </Carousel>

      <section className="ftco-section bg-light" data-aos="fade-up">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 heading-section   text-center">
              <h2 className="mb-4">Recent from blog</h2>
            </div>
          </div>
          <div className="row d-flex">
            {blogs.map((value, index) => {
              return (
                <div className="col-md-4 d-flex product-item" key={index}>
                  <div className="blog-entry align-self-stretch">
                    <div className="position-relative mt-auto">
                      <Link
                        to="/blogs"
                        className="block-20"
                        style={{
                          backgroundImage: `url(${value.image})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          height: "200px", // Set the image height to ensure consistency
                          width: "100%",
                        }}
                      ></Link>
                      <div className="product-overlay">
                        <Link
                          to="/blog"
                          className="btn btn-lg-square btn-outline-light rounded-circle d-flex align-items-center justify-content-center"
                        >
                          <i className="fa fa-eye" />
                        </Link>
                      </div>
                    </div>
                    <div className="text p-4 d-block course_color texts">
                      <div className="meta mb-3">
                        <div>
                          <Link to="#">{value.date}</Link>
                        </div>
                        <div>
                          <Link to="#">| By Dream Live</Link>
                        </div>
                        <div>
                          <Link to="#">| {value.location}</Link>
                        </div>
                      </div>
                      <h3 className="heading mt-3">
                        <Link to="#">{value.name}</Link>
                      </h3>
                      <p>{truncateDescription(value.description)}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <button
        to=""
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        className="btn  btn-primary btn-lg-square rounded-circle back-to-top"
      >
        <i
          className="bi bi-arrow-up uop"
          style={{
            textAlign: "center",
          }}
        />
      </button>
      <Footer />
    </Fragment>
  );
};

export default Home;
