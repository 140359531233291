import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Icons.css";
import client from "../Client/Client";

const Icons = () => {
  const [contact, setContact] = useState({
    phoneIndia: "",
    phoneUAE: "",
    whatsapp: "",
    email: "",
    address: "",
    youtube: "",
    facebook: "",
    tiktok: "",
    instagram: "",
  });
  useEffect(() => {
    fecthContact();
  }, []);

  const fecthContact = async () => {
    try {
      const response = await client.get("/contact/get");
      if (response.status === 200) {
        const data = response.data[0];
        setContact((pre) => {
          return {
            ...pre,
            address: data.address,
            phoneIndia: data.phoneIndia,
            phoneUAE: data.phoneUAE,
            whatsapp: data.whatsapp,
            email: data.email,
            youtube: data.youtube,
            facebook: data.facebook,
            tiktok: data.tiktok,
            instagram: data.instagram,
          };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div>
        <Link
          to=""
          onClick={() => {
            window.location.href = `tel: ${contact.phoneUAE}`;
          }}
          class="btn-phone-pulse btn-phone-pulse-border"
          title="Call Now"
          style={{
            "z-index": "100",
            "margin-bottom": "px",
          }}
        >
         <i class="bi bi-telephone-fill"></i>
        </Link>

        <Link
          onClick={() => {
            const formattedNumber = contact.whatsapp.replace(/[^0-9]/g, ""); // Removes all non-numeric characters
            window.open(`https://wa.me/${formattedNumber}`, "_blank"); // Opens in a new tab
          }}
          class="btn-whatsapp-pulse btn-whatsapp-pulse-border"
          title="Whatsapp Now"
          style={{
            "z-index": "100",
            "margin-bottom": "px",
            color:"white"
          }}
        >
     <i class="bi bi-whatsapp" ></i>
        </Link>
      </div>
    </>
  );
};

export default Icons;
