import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Icons from "../../Common/Icons/Icons";
import client from "../../Common/Client/Client";
import "./Testimonal.css";
import Header from "../../Common/Layout/Header/Header";
import Footer from "../../Common/Layout/Footer/Footer";

const Testimonal = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getReview();
  }, []);
  const getReview = async () => {
    try {
      const response = await client.get("/review/getreview");
      if (response.status === 200) {
        setReviews(response.data);
        console.log(response);
      }
    } catch (err) {
      console.log(err);
    }
  };

  console.log(reviews);
  return (
    <Fragment>
      <Header/>
      <Icons />
      <div>
      <div className="hero-wrap hero-wrap-2 blog_top_image" data-aos="fade-up" style={{backgroundImage: 'url("assets/images/bg_00002.jpg")', backgroundAttachment: 'fixed'}}>
    <div className="overlay" />
    <div className="container">
      <div className="row contact-page no-gutters slider-text align-items-center justify-content-center" data-scrollax-parent="true">
        <div className="col-md-8  text-center">
          <p className="breadcrumbs"></p>
          <h1 className="mb-3 "  style={{
                color:"#55230a",
               fontWeight:"bold",
                margin:"5px -5px 0px 20px"
              }}>Testimonial</h1>
        </div>
      </div>
    </div>
  </div>
        <section className="ftco-section bg-light" data-aos="fade-up">
          <div className="container">
            <div className="row justify-content-center mb-5 pb-3">
              <div className="col-md-7 heading-section  text-center">
                <h2 className="mb-4">Success Stories</h2>
              </div>
            </div>
            <div className="testimonials-container">
  {reviews.map((review, index) => {
    const { name, review: reviewText, rating, image, createdAt } = review;
    return (
      <div className="testimonial-card-wrapper" key={index} data-aos="fade-up">
        <div className="testimonial-card">
        <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/2/2f/Google_2015_logo.svg/368px-Google_2015_logo.svg.png"
              alt="Google logo"
              className="testimonial-card-logo"
              style={{
                alignItems:"center"
              }}
            />
          <div className="testimonial-card-header">
           
            <img src={image} alt={name} className="testimonial-card-avatar" />
            <div className="testimonial-card-info">
              <p className="testimonial-card-name">{name}</p>
            </div>
          </div>
          <p className="testimonial-card-text" style={{
            textAlign:"center"
          }}>{reviewText}</p>
          <div className="testimonial-card-rating">
            {"★".repeat(rating)}
            {"☆".repeat(5 - rating)}
          </div>
          <p className="testimonial-card-date">
            {new Date(createdAt).toLocaleDateString()}
          </p>
        </div>
      </div>
    );
  })}
</div>
          </div>
        </section>
      </div>

      <Footer/>
      <button to="" onClick={
        () => {
          window.scrollTo(0, 0);
        }
      } className="btn  btn-primary btn-lg-square rounded-circle back-to-top" ><i className="bi bi-arrow-up uop" style={{
      
        textAlign:"center"
      }}/></button>
    </Fragment>
  );
};

export default Testimonal;
