import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FcReading } from "react-icons/fc";
import { PiFileTextFill } from "react-icons/pi";
import { RiFocus3Line } from "react-icons/ri";
import "./course.css";
import client from "../../Common/Client/Client";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import toast from "react-hot-toast";
import Icons from "../../Common/Icons/Icons";
import Footer from "../../Common/Layout/Footer/Footer";
import Header from "../../Common/Layout/Header/Header";
import Loader from "../../Common/Loader/Loader";

const CourseDetails = () => {
  const location = useLocation();
  const { courseDetails , targetId } = location.state || ""
  const [courseDetail, setCourseDetail] = useState(courseDetails);
  const [loading,setLoading]=useState(false)
  console.log(courseDetail)
  const [course, setCourse] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    course: "",
    package:""
  });


  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    course: "",
    package:""
  });
  useEffect(() => {
    fetchCourse();
    if (targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [targetId]);

  const scrollToForms = () => {
    const formElement = document.getElementById("contact-details");
    if (formElement) {
      formElement.scrollIntoView({ behavior: "smooth" });
    }
  };
  const fetchCourse = async () => {
    try {
      const response = await client.get("/course/getcourse");
      if (response.status === 200) {
        setCourse(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }
    if (fieldName === "firstName" || fieldName === "lastName") {
      if (fieldValue.length < 3) {
        message = `${fieldName} is Invalid`;
      } else {
        message = "";
      }
    }

    if (fieldName === "email") {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,}@[a-zA-Z-]+\.[a-zA-Z-]{2,}$/;
      if (!emailRegex.test(fieldValue)) {
        message = `Email is Invalid`;
      } else {
        message = "";
      }
    }

    if (fieldName === "phone") {
      // Remove non-numeric characters for validation
      const numericValue = fieldValue.replace(/[^0-9]/g, "");

      if (numericValue.length < 10) {
        message = "Phone number needs 10 characters";
      } else if (numericValue.length > 10) {
        message = "Phone number is too long";
      } else {
        const prefix = parseInt(numericValue.slice(0, 2), 10);
        if (!(prefix >= 63 && prefix <= 99)) {
          message = "Invalid Phone Number";
        } else {
          message = "";
        }
      }
    }

    if (fieldName === "course") {
      if (fieldValue === "") {
        message = "course is required";
      } else {
        message = "";
      }
    }
    if(fieldName==="package"){
      if(fieldValue===""){
        message="package is required";
        }else{
          message="";
        }
    }
    return { message: message };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setErrors((prevError) => ({
      ...prevError,
      [name]: err,
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setErrors((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      formData.course === "" ||
      formData.email === "" ||
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.phone === "" ||
      formData.package === ""
    ) {
      toast.error("Please fill all the fields");
    } else if (
      errors.course !== "" ||
      errors.email !== "" ||
      errors.firstName !== "" ||
      errors.lastName !== "" ||
      errors.phone !== "" ||
      errors.package !== ""
    ) {
      setErrors((pre) => ({
        ...pre,
        course: "Course is required",
        email: "Email is required",
        firstName: "Firstname is required",
        lastName: "Lastname is required",
        phone: "phone number is reqiured",
        package:"Package is required"
      }));
      setFormData((pre) => ({
        ...pre,
        course: "",
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        package: ""
      }));
    } else {
      enqiureData();
    }
  };

  const enqiureData = async () => {
    try {
      setLoading(true)
      const response = await client.post("/register/postdata", formData);
      if (response.status === 200) {
        const formDatas = new FormData();
        formDatas.append("First Name", formData.firstName);
        formDatas.append("Last Name", formData.lastName);
        formDatas.append("Email", formData.email);
        formDatas.append("Course", formData.course);
        formDatas.append("Package", formData.package);
        formDatas.append("Phone Number", formData.phone);

        formDatas.append("access_key", "a8fec308-f200-40a6-87b4-93f9679c44d5");

        const object = Object.fromEntries(formDatas);
        console.log(object)
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: json,
        }).then((res) => res.json());

        if (res.success) {
        toast.success("Data has been sent successfully");
        setErrors((pre) => ({
          ...pre,
          course: "",
          email: "",
          firstName: "",
          lastName: "",
          phone: "",
          package:""
        }));
        setFormData((pre) => ({
          ...pre,
          course: "",
          email: "",
          firstName: "",
          lastName: "",
          phone: "",
          package:""
        }));
        }
       setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.log(err);
      toast.error("Please try again later ");
    }
  };

  return (
    <Fragment>
      <Header />
      <Icons />
      <div>
      <div
          className="hero-wrap hero-wrap-2 "
          style={{
            backgroundImage: 'url("assets/images/bg_02.jpg")',
            backgroundAttachment: "fixed",
            zIndex:"3",

          }}
          data-aos="fade-up"
        >
          <div className="overlay" />
          <div className="container">
            <div
              className="row no-gutters slider-text align-items-center justify-content-center"
              data-scrollax-parent="true"
            >
              <div className="col-md-8  text-center">
                <p className="breadcrumbs">
                 
                </p>
                <h1 className="mb-3 "  style={{
                color:"#55230a",
               fontWeight:"bold",
                margin:"5px 0px 0px 20px"
              }}>Courses Deatils</h1>
              </div>
            </div>
          </div>
        </div>
        <section className=" py-5">
        {courseDetail &&  <div className="coursepage">
            <div className="container">
              <div className="main">
                <div className="row text-center mb-5" data-aos="fade-up">
                  <div className="col-md-12 mb-4">
                    <div className="hero-container d-flex align-items-center">
                      <div className="hero-text ml-4">
                        <h1 className="font-style  mb-3 mt-4">
                          Manifest Your Dream with the Best{" "}
                          {courseDetail.courseName} Online Coaching Centre in
                          Kerala
                        </h1>
                        <button
                          className="btn btn-primary"
                          onClick={scrollToForms}
                        >
                          Join Now
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row text-center" data-aos="fade-up">
                  <div className="col-md-12 mb-4">
                    <div className="heading-unique">
                      <h2 className="heading-unique-title">Why Choose Us?</h2>
                      <p
                        className="heading-unique-subtitle"
                        style={{
                          color: "white",
                        }}
                      >
                        {courseDetail.whyChooseUs}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row text-center" data-aos="fade-up">
                  <div className="col-md-12 mb-4">
                    <h1 className="custom-heading mb-4">
                      Why should one prepare for the {courseDetail.courseName}?
                    </h1>
                  </div>
                  <div className="col-md-4 mb-4 d-flex">
                    <div className="feature-box d-flex flex-column justify-content-between">
                      <span className="feature-icon">
                        <FcReading />
                      </span>
                      <h3 className="feature-title">Purpose</h3>
                      <p className="feature-text">{courseDetail.purpose}</p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 d-flex">
                    <div className="feature-box d-flex flex-column justify-content-between">
                      <span className="feature-icon">
                        <RiFocus3Line />
                      </span>
                      <h3 className="feature-title">Focus</h3>
                      <p className="feature-text">{courseDetail.focus}</p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4 d-flex">
                    <div className="feature-box d-flex flex-column justify-content-between">
                      <span className="feature-icon">
                        <PiFileTextFill />
                      </span>
                      <h3 className="feature-title">Test Format</h3>
                      <p className="feature-text">{courseDetail.testFormat}</p>
                    </div>
                  </div>
                </div>

                <div data-aos="fade-up">
                  {courseDetail.coursePreparation.length > 1 && (
                    <div className=" py-5">
                      <h1 className="text-center mb-5 custom-heading">
                        Why Dream Live Stands Out for Your{" "}
                        {courseDetail.courseName} Preparation?
                      </h1>
                      <div className="row">
                        {courseDetail.coursePreparation.map((item, index) => (
                          <div key={index} className="about-course">
                            <div className="preparation-point d-flex align-items-start">
                              <span className="preparation-icon">
                                <i className="fas fa-check-circle"></i>
                              </span>
                              <p className="preparation-text mb-0">{item}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <div data-aos="fade-up">
                  {courseDetail.courseCoaching.length > 1 && (
                    <div className=" py-5">
                      <h1 className="text-center mb-5 custom-heading">
                        Our Approach towards the {courseDetail.courseName}{" "}
                        Coaching
                      </h1>
                      <div className="row">
                        {courseDetail.courseCoaching.map((item, index) => (
                          <div key={index} className="about-course">
                            <div className="preparation-point d-flex align-items-start">
                              <span className="preparation-icon">
                                <i className="fas fa-check-circle"></i>
                              </span>
                              <p className="preparation-text mb-0">{item}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div data-aos="fade-up">
                  <h1 className="text-center mb-5 custom-heading">
                    What's {courseDetail.courseName} ?
                  </h1>
                  {courseDetail.aboutCourse.map((item, index) => {
                    return (
                      <div key={index} className="about-course">
                        <div className="preparation-point d-flex align-items-start">
                          <span className="preparation-icon">
                            <i className="fas fa-check-circle"></i>
                          </span>
                          <p className="preparation-text mb-0">{item}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div data-aos="fade-up">
                  <div className=" py-5">
                    <h1 className="text-center mb-4  custom-heading">
                      Structure of {courseDetail.courseName}Exam
                    </h1>
                    <div className="table-responsive">
                      <table className="table table-bordered table-hover">
                        <thead className="thead-light">
                          <tr>
                            <th>Component</th>
                            <th>Duration</th>
                            <th>Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {courseDetail.examStructure.map((item, index) => (
                            <tr key={index}>
                              <td>{item.component}</td>
                              <td>{item.duration}</td>
                              <td>
                                <ul>
                                  {item.description.map((desc, i) => (
                                    <li
                                      key={i}
                                      style={{
                                        textAlign: "left",
                                      }}
                                    >
                                      {desc}
                                    </li>
                                  ))}
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div data-aos="fade-up">
                  {courseDetail.coachingExcellence.length > 1 && (
                    <div className=" py-5">
                      <h1 className="text-center mb-5  custom-heading">
                        Dream Live sets out the Standard in{" "}
                        {courseDetail.courseName} Coaching Excellence
                      </h1>
                      <div className="row">
                        {courseDetail.coachingExcellence.map((item, index) => (
                          <div key={index} className="about-course">
                            <div className="preparation-point d-flex align-items-start">
                              <span className="preparation-icon">
                                <i className="fas fa-check-circle"></i>
                              </span>
                              <p className="preparation-text mb-0">{item}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>

                <div data-aos="fade-up">
                  {courseDetail.academicYear.length > 1 && (
                    <div className=" py-5">
                      <h2 className="text-center mb-4  custom-heading">
                        {courseDetail.courseName} Exam Dates in the Academic
                        Year
                      </h2>
                      <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th>Months</th>
                              <th>Dates</th>
                            </tr>
                          </thead>
                          <tbody>
                            {courseDetail.academicYear.map((item, index) => (
                              <tr key={index}>
                                <td>{item.month}</td>
                                <td>
                                  <ul>
                                    {item.dates.map((dates, i) => (
                                      <li
                                        key={i}
                                        style={{
                                          textAlign: "left",
                                        }}
                                      >
                                        {dates}
                                      </li>
                                    ))}
                                  </ul>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
                <div  id="package"></div>

                <div className=" py-5" data-aos="fade-up">
                  <div className="row mb-4">
                    <div className="col text-center">
                      <h1 className=" custom-heading">
                     {courseDetail.courseName}  Course Plan and Packages
                      </h1>
                    </div>
                  </div>

                  <div className="packagemargin">
                    {courseDetail.package.map((item, index) => {
                      return (
                        <div key={index} className="plan">
                          <div class="inner">
                            <span class="pricing">
                              <span>₹{item.packagePrice}</span>
                            </span>
                            <p class="title">{item.packageName}</p>
                           
                            <ul class="features">
                              <li>
                            <span class="icons">
                                      <i className="fas fa-check-circle"></i>
                                    </span>
                                    <span
                                      style={{
                                        textAlign: "left",
                                        marginLeft: "5px",
                                      }}
                                    >
                                    Course Duration : {item.packageDuration}
                                    </span>
                                  </li>
                              {item.keyFeatures.map((feature, index) => {
                                return (
                                  <li>
                                    <span class="icons">
                                      <i className="fas fa-check-circle"></i>
                                    </span>
                                    <span
                                      style={{
                                        textAlign: "left",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {feature}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                            <div class="action">
                            
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div id="contact-details"></div>
              <section
                className="course-user-selection-form-section"
                
                data-aos="fade-up"
              >
                <h1 className="course-user-selection-form-title">
                  Connect with Us
                </h1>
                <form
                  className="course-user-selection-enquiry-form"
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={2}>
                    <Grid
                      item
                      size={{ xs: 12, sm: 6 }}
                      className="course-user-selection-form-grid-item"
                    >
                      <TextField
                        name="firstName"
                        label="First Name"
                        variant="outlined"
                        fullWidth
                        value={formData.firstName}
                        onChange={handleChange}
                        error={!!errors.firstName}
                        helperText={errors.firstName}
                        onBlur={handleBlur}
                        className="course-user-selection-input-field"
                        slotProps={{
                          htmlInput: {
                            maxLength: 10,
                          },
                        }}
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                          ];
                          const allowedCharPattern = /^[A-Za-z._-]$/;
                          if (
                            !allowedKeys.includes(e.key) &&
                            !allowedCharPattern.test(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      size={{ xs: 12, sm: 6 }}
                      className="course-user-selection-form-grid-item"
                    >
                      <TextField
                        name="lastName"
                        label="Last Name"
                        variant="outlined"
                        fullWidth
                        value={formData.lastName}
                        onChange={handleChange}
                        error={!!errors.lastName}
                        helperText={errors.lastName}
                        onBlur={handleBlur}
                        className="course-user-selection-input-field"
                        slotProps={{
                          htmlInput: {
                            maxLength: 10,
                          },
                        }}
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                          ];
                          const allowedCharPattern = /^[A-Za-z._-]$/;
                          if (
                            !allowedKeys.includes(e.key) &&
                            !allowedCharPattern.test(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      size={{ xs: 12, sm: 6 }}
                      className="course-user-selection-form-grid-item"
                    >
                      <TextField
                        name="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                        value={formData.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        onBlur={handleBlur}
                        className="course-user-selection-input-field"
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                          ];
                          const allowedCharPattern = /^[0-9a-z._@-]$/;
                          if (
                            !allowedKeys.includes(e.key) &&
                            !allowedCharPattern.test(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      size={{ xs: 12, sm: 6 }}
                      className="course-user-selection-form-grid-item"
                    >
                      <TextField
                        name="phone"
                        label="Phone Number"
                        variant="outlined"
                        fullWidth
                        value={formData.phone}
                        onChange={handleChange}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        onBlur={handleBlur}
                        className="course-user-selection-input-field"
                        slotProps={{
                          htmlInput: {
                            maxLength: 10,
                          },
                        }}
                        onKeyDown={(e) => {
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                            "Tab",
                          ];
                          const allowedCharPattern = /^[0-9]$/;
                          if (
                            !allowedKeys.includes(e.key) &&
                            !allowedCharPattern.test(e.key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      size={12}
                      className="course-user-selection-form-grid-item"
                    >
                      <FormControl
                        fullWidth
                        error={!!errors.course}
                        className="course-user-selection-input-field"
                      >
                        <InputLabel>Course Selection</InputLabel>
                        <Select
                          name="course"
                          value={formData.course}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Course Selection"
                        >
                          {course.map((course, index) => (
                            <MenuItem key={index} value={course.courseName}>
                              {course.courseName}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.course && (
                          <p className="error-message">{errors.course}</p>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      size={12}
                      className="course-user-selection-form-grid-item"
                    >
                      <FormControl
                        fullWidth
                        error={!!errors.package}
                        className="course-user-selection-input-field"
                      >
                        <InputLabel>Package Selection</InputLabel>
                        <Select
                          name="package"
                          value={formData.package}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="Course Selection"
                        >
                          {courseDetail.package.map((course, index) => (
                            <MenuItem key={index} value={course.packageName}>
                              {course.packageName}
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.package && (
                          <p className="error-message">{errors.package}</p>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      size={12}
                      textAlign="center"
                      className="course-user-selection-form-grid-item"
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        className="course-user-selection-submit-button"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </section>
            </div>

            <div className="faq" data-aos="fade-up">
              <h2 className="faq-heading">Frequently Asked Questions</h2>
              <div>
                <Accordion className="accordion">
                  <AccordionSummary
                    className="accordion-summary"
                    expandIcon={
                      <ArrowDropDownIcon className="accordion-icon" />
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className="accordion-text">
                      What is Dream Live?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <Typography>
                      Dream Live is a premier provider of training courses
                      designed to help healthcare professionals prepare for the
                      OET (Occupational English Test), PTE (Pearson Test of
                      English), and OSCE (Objective Structured Clinical
                      Examination).
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="accordion">
                  <AccordionSummary
                    className="accordion-summary"
                    expandIcon={
                      <ArrowDropDownIcon className="accordion-icon" />
                    }
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography className="accordion-text">
                      What makes Dream Live’s OET, PTE, and OSCE preparation
                      courses unique?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <Typography>
                      Dream Live’s courses are distinguished by personalized
                      learning plans, expert instructors, comprehensive
                      resources, flexible scheduling, and a supportive
                      community. Our focus on individualized support ensures
                      that you receive the attention you need to succeed.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="accordion">
                  <AccordionSummary
                    className="accordion-summary"
                    expandIcon={
                      <ArrowDropDownIcon className="accordion-icon" />
                    }
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography className="accordion-text">
                      How do I choose between OET, PTE, and OSCE preparation
                      courses at Dream Live?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <Typography>
                      The choice depends on your career goals and requirements.
                      OET is tailored for healthcare professionals, PTE is a
                      general English proficiency test, and OSCE is specific to
                      clinical assessments. Our counselors can help you
                      determine the best course based on your needs.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="accordion">
                  <AccordionSummary
                    className="accordion-summary"
                    expandIcon={
                      <ArrowDropDownIcon className="accordion-icon" />
                    }
                    aria-controls="panel3-content"
                    id="panel3-header"
                  >
                    <Typography className="accordion-text">
                      What qualifications do the instructors at Dream Live have?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <Typography>
                      Our instructors are highly experienced professionals with
                      backgrounds in healthcare and language testing. They
                      possess advanced degrees and certifications, along with
                      practical experience in their respective fields.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="accordion">
                  <AccordionSummary
                    className="accordion-summary"
                    expandIcon={
                      <ArrowDropDownIcon className="accordion-icon" />
                    }
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    <Typography className="accordion-text">
                      How long are the preparation courses, and what is the
                      typical duration?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <Typography>
                      The duration of our courses varies depending on the
                      program and your individual goals. Typically, courses
                      range from a few weeks to several months. We offer
                      flexible schedules to fit your needs, whether you’re
                      looking for an intensive program or a more extended study
                      period.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="accordion">
                  <AccordionSummary
                    className="accordion-summary"
                    expandIcon={
                      <ArrowDropDownIcon className="accordion-icon" />
                    }
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    <Typography className="accordion-text">
                      How does Dream Live support students during their
                      preparation for the OET, PTE, or OSCE exams?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <Typography>
                      We provide personalized feedback, access to practice
                      materials, one-on-one mentoring, and continuous support
                      throughout your preparation.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion className="accordion">
                  <AccordionSummary
                    className="accordion-summary"
                    expandIcon={
                      <ArrowDropDownIcon className="accordion-icon" />
                    }
                    aria-controls="panel4-content"
                    id="panel4-header"
                  >
                    <Typography className="accordion-text">
                      What support is available during the course?
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <Typography>
                      You’ll receive ongoing support through personalized
                      feedback, mentorship, and access to comprehensive
                      resources.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>}
        </section>
      </div>
      <Footer />
      <button
        to=""
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        className="btn  btn-primary btn-lg-square rounded-circle back-to-top"
      >
        <i
          className="bi bi-arrow-up uop"
          style={{
            textAlign: "center",
          }}
        />
      </button>
      {loading && <Loader/>}
    </Fragment>
  );
};

export default CourseDetails;
