import React, { Fragment, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import "./Contact.css";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import client from "../../Common/Client/Client";
import toast from "react-hot-toast";
import Grid from "@mui/material/Grid2";
import Icons from "../../Common/Icons/Icons";
import Header from "../../Common/Layout/Header/Header";
import Footer from "../../Common/Layout/Footer/Footer";
import Loader from "../../Common/Loader/Loader";
const Contact = () => {
  const [contact, setContact] = useState({
    phoneIndia: "",
    phoneUAE: "",
    whatsapp: "",
    email: "",
    address: "",
  });
  const [loading,setLoading]=useState(false)
  const location=useLocation()
  const {  targetId } = location.state || ""
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });
  const [quotes, setQuotes] = useState([]);
  useEffect(() => {
    fecthContact();
    getQuotes();
 
      if (targetId) {
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, [targetId]);


    
  const getQuotes = async () => {
    try {
      const response = await client.get("/quotes/getquotes", {
        withCredentials: true,
      });
      if (response.status === 200) {
        setQuotes(response.data);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to fetch quotes details");
      }
    }
  };

  const fecthContact = async () => {
    try {
      const response = await client.get("/contact/get");
      if (response.status === 200) {
        const data = response.data[0];
        setContact((pre) => {
          return {
            ...pre,
            address: data.address,
            phoneIndia: data.phoneIndia,
            phoneUAE: data.phoneUAE,
            whatsapp: data.whatsapp,
            email: data.email,
          };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevState) => ({
        ...prevState,
        [name]: `${name} field is required`,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setData({ ...data, [name]: value });
  };

  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "firstName" || fieldName === "lastName") {
      if (fieldValue.length < 3) {
        message = `${fieldName} is Invalid`;
      } else {
        message = "";
      }
    }

    if (fieldName === "email") {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,}@[a-zA-Z-]+\.[a-zA-Z-]{2,}$/;
      if (!emailRegex.test(fieldValue)) {
        message = `Email is Invalid`;
      } else {
        message = "";
      }
    }

    if (fieldName === "phoneNumber") {
      // Remove non-numeric characters for validation
      const numericValue = fieldValue.replace(/[^0-9]/g, "");

      if (numericValue.length < 10) {
        message = "Phone number needs 10 characters";
      } else if (numericValue.length > 10) {
        message = "Phone number is too long";
      } else {
        const prefix = parseInt(numericValue.slice(0, 2), 10);
        if (!(prefix >= 63 && prefix <= 99)) {
          message = "Invalid Phone Number";
        } else {
          message = "";
        }
      }
    }

    if (fieldName === "message") {
      if (fieldValue.length < 20) {
        message = "Message is invalid";
      } else {
        message = "";
      }
    }

    return { message: message };
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    if (
      data.firstName === "" ||
      data.lastName === "" ||
      data.email === "" ||
      data.phoneNumber === "" ||
      data.message === ""
    ) {
      toast.error("Please fill the all field");
    } else if (
      error.firstName !== "" ||
      error.lastName !== "" ||
      error.email !== "" ||
      error.phoneNumber !== "" ||
      error.message !== ""
    ) {
      setError((prev) => {
        return {
          ...prev,
          firstName: "First Name is required",
          lastName: "Last Name is required",
          email: "Email  is required",
          phoneNumber: "Phone Number is required",
          message: "Message is required",
        };
      });
      setData((pre) => {
        return {
          ...pre,
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          message: "",
        };
      });
    } else {
      sendData();
    }
  };

  const sendData = async () => {
    try {
      setLoading(true)
      const res = await client.post("/user/enqiure", data);
      if (res.status === 200) {
        
        const formDatas = new FormData();
        formDatas.append("First Name", data.firstName);
        formDatas.append("Last Name", data.lastName);
        formDatas.append("Email", data.email);
        formDatas.append("Message", data.message);
      
        formDatas.append("Phone Number", data.phoneNumber);

        formDatas.append("access_key", "a8fec308-f200-40a6-87b4-93f9679c44d5");

        const object = Object.fromEntries(formDatas);
        console.log(object)
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: json,
        }).then((res) => res.json());

        if (res.success) {
        toast.success("Data has been sent successfully");
        setLoading(false)
        setError((prev) => {
          return {
            ...prev,
            firstName: "",
            lastName: "",
            email:"",
            phoneNumber:"",
            message: "",
          };
        });
        setData((pre) => {
          return {
            ...pre,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            message: "",
          };
        });
        }
       
       
      }
    } catch (err) {
      setLoading(false)
      setError((prevError) => ({
        ...prevError,
        firstName: "First Name is required",
        lastName: "Last Name is required",
        email: "Email  is required",
        phoneNumber: "Phone Number is required",
        message: "Message is required",
      }));
    }
  };
  return (
    <Fragment>
      <Header/>
      <Icons/>
      <div>
      <div
        className="hero-wrap hero-wrap-2 "
         data-aos="fade-up"
        style={{
          backgroundImage: 'url("assets/images/bg_2.jpg")',
          backgroundAttachment: "fixed",
          backgroundPosition:"top"
        }}
      >
        <div className="overlay" />
        <div className="container">
          <div
            className="row contact-page no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div className="col-md-8  text-center">
              <p className="breadcrumbs">
              
              </p>
              <h1 className="mb-3  fw-bolder" style={{
                color:"#55230a",
               fontWeight:"bold",
                margin:"5px 0px 0px 20px"
              }}>Contact</h1>
            </div>
          </div>
        </div>
      </div>
        <section className="contact-section" data-aos="fade-up" >
          <div className="container">
            <div className="contact-header">
              <h2>Contact Information</h2>
            </div>
            <div className="contact-grid" data-aos="fade-up">
              <div className="contact-card" data-aos="fade-up">
                <div className="icon-boxs">
                  <i className="fas fa-map-marker-alt"></i>
                </div>
                <div className="info-box">
                  <h4>Address</h4>
                  <p>{contact.address}</p>
                </div>
              </div>

              <div className="contact-card" data-aos="fade-up">
                <div className="icon-boxs">
                  <i className="fas fa-envelope"></i>
                </div>
                <div className="info-box">
                  <h4>Email</h4>
                  <p
                    onClick={() => {
                      window.location.href = `mailto:${contact.email}`;
                    }}
                  >
                    {contact.email}
                  </p>
                </div>
              </div>

              <div className="contact-card" data-aos="fade-up">
                <div className="icon-boxs">
                <i class="fa-solid fa-phone"></i>
                </div>
                <div className="info-box">
                  <h4>Phone (India)</h4>
                  <p
                    onClick={() => {
                      window.location.href = `tel: ${contact.phoneIndia}`;
                    }}
                  >
                    {contact.phoneIndia}
                  </p>
                </div>
              </div>

              <div className="contact-card " data-aos="fade-up">
                <div className="icon-boxs">
                <i class="fa-solid fa-phone"></i>
                </div>
                <div className="info-box">
                  <h4>Phone (UAE)</h4>
                  <p
                    onClick={() => {
                      const formattedPhone = contact.phoneUAE.replace(
                        /\s+/g,
                        ""
                      ); // Remove spaces
                      window.location.href = `tel:${formattedPhone}`;
                    }}
                  >
                    {contact.phoneUAE}
                  </p>
                </div>
              </div>

              <div className="contact-card " data-aos="fade-up">
                <div className="icon-boxs">
                  <i className="fab fa-whatsapp"></i>
                </div>
                <div className="info-box">
                  <h4>WhatsApp</h4>
                  <p
                    onClick={() => {
                      const formattedNumber = contact.whatsapp.replace(
                        /[^0-9]/g,
                        ""
                      ); // Removes all non-numeric characters
                      window.open(`https://wa.me/${formattedNumber}`, "_blank"); // Opens in a new tab
                    }}
                  >
                    {contact.whatsapp}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="contact"></div>
     
        <Carousel fade slide={false} data-aos="fade-up">
        {quotes.map((quote, index) => {
          return (
            <Carousel.Item>
              <section
                className="ftco-section-3 img quotes_image"
                style={{
                  backgroundImage: `url(${quote.image})`,
                  padding: "13em 0px",
                }}
              >
                <div
                  className="overlay"
                  style={{
                    background:
                      "linear-gradient(to right, #6a00ff 0%, #ffd900 100%)",
                  }}
                />
                <div className="container quotes-container">
                  <div className="row d-md-flex justify-content-center">
                    <div className="col-md-9 about-video text-center ">
                      {/* Quote */}
                      <p
                        className="give"
                        style={{
                          color: "#fff",

                          padding: "0px 40px",
                        }}
                      >
                        "{quote.quotes}"
                      </p>

                      {/* Author Name */}
                      <p
                        className="give1"
                        style={{
                          color: "#fff",
                          fontStyle: "italic",
                        }}
                      >
                        –{quote.name}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </Carousel.Item>
          );
        })}
      </Carousel>
       
        <section
  className="contact-form-section"
  style={{
    marginBottom: "20px",
    marginTop: "20px",
  }}
  data-aos="fade-up"
>
  <h1 className="form-title">Get in Touch</h1>
  <form className="contact-form">
  <div className="form-fileds">
    <Grid container spacing={2}>
      <Grid
        item
        size={{
          xs: 12,
          sm: 6,
        }}
        className="contact-form-grid-item"
      >
       
          <div className="form-group">
      
        <TextField
          id="first-name"
          className="contact-form-input"
          label="First Name"
          name="firstName"
          value={data.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!error.firstName}
          helperText={error.firstName}
          fullWidth
          variant="outlined"
          slotProps={{
            htmlInput: {
              maxLength: 10,
            },
          }}
          onKeyDown={(e) => {
            const allowedKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Delete",
              "Tab",
            ];
            const allowedCharPattern = /^[A-Za-z._-]$/;

            if (
              !allowedKeys.includes(e.key) &&
              !allowedCharPattern.test(e.key)
            ) {
              e.preventDefault();
            }
          }}
        />
        </div>
      </Grid>
      <Grid
        item
        size={{
          xs: 12,
          sm: 6,
        }}
        className="contact-form-grid-item"
      >
          <div className="form-group">
        <TextField
          fullWidth
          id="last-name"
          className="contact-form-input"
          label="Last Name"
          name="lastName"
          value={data.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!error.lastName}
          helperText={error.lastName}
          variant="outlined"
          slotProps={{
            htmlInput: {
              maxLength: 10,
            },
          }}
          onKeyDown={(e) => {
            const allowedKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Delete",
              "Tab",
            ];
            const allowedCharPattern = /^[A-Za-z._-]$/;

            if (
              !allowedKeys.includes(e.key) &&
              !allowedCharPattern.test(e.key)
            ) {
              e.preventDefault();
            }
          }}
        />
        </div>
      </Grid>

      <Grid
        item
        size={{
          xs: 12,
          sm: 6,
        }}
        className="contact-form-grid-item"
      >
          <div className="form-group">
        <TextField
          fullWidth
          id="email"
          className="contact-form-input"
          label="Email"
          value={data.email}
          name="email"
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!error.email}
          helperText={error.email}
          variant="outlined"
          onKeyDown={(e) => {
            const allowedKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Delete",
              "Tab",
            ];
            const allowedCharPattern = /^[0-9a-z._@-]$/;

            if (
              !allowedKeys.includes(e.key) &&
              !allowedCharPattern.test(e.key)
            ) {
              e.preventDefault();
            }
          }}
        />
        </div>
      </Grid>
      <Grid
        item
        size={{
          xs: 12,
          sm: 6,
        }}
        className="contact-form-grid-item"
      >
          <div className="form-group">
        <TextField
          id="phone-number"
          fullWidth
          className="contact-form-input"
          label="Phone Number"
          variant="outlined"
          name="phoneNumber"
          value={data.phoneNumber}
          slotProps={{
            htmlInput: {
              maxLength: 10,
            },
          }}
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!error.phoneNumber}
          helperText={error.phoneNumber}
          onKeyDown={(e) => {
            const allowedKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Delete",
              "Tab",
            ];
            const allowedCharPattern = /^[0-9]$/;

            if (
              !allowedKeys.includes(e.key) &&
              !allowedCharPattern.test(e.key)
            ) {
              e.preventDefault();
            }
          }}
        />
        </div>
      </Grid>

      <Grid item size={12} className="contact-form-grid-item">
      <div className="form-group">
        <TextField
          id="message"
          fullWidth
          className="contact-form-input"
          multiline
          rows={4}
          value={data.message}
          name="message"
          onChange={handleChange}
          onBlur={handleBlur}
          error={!!error.message}
          helperText={error.message}
          onKeyDown={(e) => {
            const allowedKeys = [
              "Backspace",
              "ArrowLeft",
              "ArrowRight",
              "Delete",
              "Tab",
              " ",
            ];
            const allowedCharPattern = /^[A-Za-z0-9._-]$/;

            if (data.message.length === 0 && e.key === " ") {
              e.preventDefault();
              return;
            }

            if (
              !allowedKeys.includes(e.key) &&
              !allowedCharPattern.test(e.key)
            ) {
              e.preventDefault();
            }
          }}
          style={{
            width: "100%",
          }}
          label="Message"
          variant="outlined"
        />
        </div>
      </Grid>

      <Grid
        item
        size={12}
        textAlign="center"
        className="contact-form-grid-item"
      >
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="contact-form-submit-button"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
    </div>
  </form>
</section>

      </div>
      <Footer/>
      <button to="" onClick={
        () => {
          window.scrollTo(0, 0);
        }
      } className="btn  btn-primary btn-lg-square rounded-circle back-to-top" ><i className="bi bi-arrow-up uop" style={{
      
        textAlign:"center"
      }}/></button>
      {loading && <Loader/>}
    </Fragment>
  );
};

export default Contact;
