import React, { Fragment } from "react";
import { Link,useLocation } from "react-router-dom";
import "./Header.css";
import logo from '../../../../assets/images/logo.jpeg';

const Header = () => {
  const location = useLocation();

  // Collapse the navbar after route change
  const handleNavCollapse = () => {
    const navBar = document.getElementById('ftco-nav');
    if (navBar && navBar.classList.contains('show')) {
      navBar.classList.remove('show'); // Collapse the navbar
    }
  };

  return (
    <Fragment>
      <nav
        className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
        id="ftco-navbar"
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="logo" className="logo" />
          </Link>
          <p
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#ftco-nav"
            aria-controls="ftco-nav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="oi oi-menu" /> 
          </p>
          <div className="collapse navbar-collapse custom_nav-container" id="ftco-nav">
  <ul className="navbar-nav ml-auto">
    <li className={`nav-item ${location.pathname === "/" ? "active" : ""}`}>
      <Link to="/" className="nav-link" onClick={handleNavCollapse}>
        Home
      </Link>
    </li>
    <li className={`nav-item ${location.pathname === "/about" ? "active" : ""}`}>
      <Link to="/about" className="nav-link" onClick={handleNavCollapse}>
        About
      </Link>
    </li>
    <li className={`nav-item ${location.pathname === "/course" ? "active" : ""}`}>
      <Link to="/course" className="nav-link" onClick={handleNavCollapse}>
        Courses
      </Link>
    </li>
    <li className={`nav-item dropdown ${(location.pathname === "/images" || location.pathname === "/videos") ? "active" : ""}`}>
      <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
        Media
      </Link>
      <ul className="dropdown-menu">
        <li>
          <Link to="/images" className="dropdown-item" style={{ color: "#55230a" }} onClick={handleNavCollapse}>
            Image
          </Link>
        </li>
        <li>
          <Link to="/videos" className="dropdown-item" style={{ color: "#55230a" }} onClick={handleNavCollapse}>
            Video
          </Link>
        </li>
      </ul>
    </li>
    <li className={`nav-item ${location.pathname === "/blog" ? "active" : ""}`}>
      <Link to="/blog" className="nav-link" onClick={handleNavCollapse}>
        Blog
      </Link>
    </li>
    <li className={`nav-item ${location.pathname === "/testimonals" ? "active" : ""}`}>
      <Link to="/testimonals" className="nav-link" onClick={handleNavCollapse}>
        Testimonials
      </Link>
    </li>
    <li className={`nav-item ${location.pathname === "/contact" ? "active" : ""}`}>
      <Link to="/contact" className="nav-link" onClick={handleNavCollapse}>
        Contact
      </Link>
    </li>
  </ul>
</div>


        </div>
      </nav>
    </Fragment>
  );
};

export default Header;
