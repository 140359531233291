import React, { Fragment, useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./About.css";
import Icons from "../../Common/Icons/Icons";
import Header from "../../Common/Layout/Header/Header";
import Footer from "../../Common/Layout/Footer/Footer";
import client from "../../Common/Client/Client";
import toast from "react-hot-toast";

const About = () => {
  const navigate=useNavigate();
  const [url, setUrl] = useState([]);
  const [founder, setFounder] = useState([]);
  const [about,setAbout]=useState([])
  useEffect(() => {
    getFounder();
    getAbouts();
    getlinks();
  }, []);
  const getFounder = async () => {
    try {
      const response = await client.get("/founder/getFounder",{
        withCredentials:true
      });
      if (response.status === 200) {
        setFounder(response.data);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to fetch founder details");
      }
    }
  };

  const getAbouts=async()=>{
    try{
      const response=await client.get('/about/getabout')
      if(response.status===200){
        const data=response.data[0]
     
        setAbout([data])
           
       
      }
    }catch(err){
      console.log(err)

    }
  }

  const getlinks = async () => {
    try {
      const response = await client.get("/gallery/getyoutubeurl");
      if (response.status === 200) {
       setUrl(response.data.slice(0, 3));
      }
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  };


  const getEmbedUrl = (videoUrl) => {
    console.log(videoUrl);
    if (typeof videoUrl !== "string") {
      return ""; // Return an empty string if videoUrl is not a string
    }

    const videoIdMatch = videoUrl.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    if (videoIdMatch) {
      const videoId = videoIdMatch[1];
      return `https://www.youtube.com/embed/${videoId}`;
    }

    return ""; // Return an empty string if no video ID is found
  };
  return (
    <Fragment>
      <Header/>
      <Icons/>
      <div
        className="hero-wrap hero-wrap-2 "
         data-aos="fade-up"
        style={{
          backgroundImage: 'url("assets/images/bg_2.jpg")',
          backgroundAttachment: "fixed",
          backgroundPosition:"top"
        }}
      >
        <div className="overlay" />
        <div className="container">
          <div
            className="row contact-page no-gutters slider-text align-items-center justify-content-center"
            data-scrollax-parent="true"
          >
            <div className="col-md-8  text-center">
              <p className="breadcrumbs">
              
              </p>
              <h1 className="mb-3  fw-bolder" style={{
                color:"#55230a",
               fontWeight:"bold",
                margin:"5px 0px 0px 20px"
              }}>About</h1>
            </div>
          </div>
        </div>
      </div>
      <section className="ftco-section"  data-aos="fade-up">
        <div className="container">
        
            {
              about.map((about,index)=>{
                return(
                  <div className="row d-flex local-about">
                      <div className="col-md-6 d-flex  local-abouts">
              <div
                className="img img-about align-self-stretch "
                style={{
                  backgroundImage:`url(${about.image})`,
                  width: "100%",
                  
                }}
              />
            </div>
            <div
              className="col-md-6 pl-md-5 local-abouts"
              style={{
                textAlign: "left",
              }}
            >
              <h2
                className="mb-4"
                style={{
                  textAlign: "center",
                }}
              >
                OUR ULTIMATE VISION IS YOUR SUCCESS.
              </h2>

              {
                about.about.map((value)=>{
                  return(
                    <p
                style={{
                  fontSize: "15px",
                  textAlign: "left",
                  marginTop:"8px",
                   fontFamily: "'Poppins', sans-serif",
                }}
              >
                {value}
                </p>

                  )
                })
              }
              
            
            
              <button
                className="btn btn-primary readmore"
                onClick={() =>navigate("/course")}
                style={{
                  textAlign: "left",
                  display: "inline-block",
                  marginTop: "10px",
                  fontSize: "1.1rem",
                  textDecoration: "none",
                  fontWeight: "500",
                  backgroundColor: "#167ce9",
                  color: "white",
                }}
              >
                Read More
              </button>
            </div>
                    </div>
                )
              })
            }
          
          
        </div>
      </section>
      <section
        className="ftco-section-3 img"
        style={{
          backgroundImage: "url(assets/images/course-4.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="overlay" />
        <div className="container">
          <div className="row d-md-flex justify-content-center">
            <div className="col-md-9 about-video text-center">
      
         <h2 className=""     data-aos="fade-up">
               At DREAM LIVE, we offer personalized OET preparation to help healthcare professionals succeed.
              </h2>
             
            </div>
          </div>
        </div>
      </section>
      <section
        className="ftco-section"
        style={{
          padding: "7em 0px 0px 0px",
        }}
        data-aos="fade-up"
      >
        <div className="container">
          <div className="row justify-content-center mb-5 pb-3">
            <div className="col-md-7 heading-section   text-center">
              <h2 className="mb-4">Our Videos</h2>
            </div>
          </div>
          <div className="row videos_row">
              {url.map((item, index) => (
                <div
                  key={index}
                  className="col-lg-4 mb-sm-4 "
                  data-aos="fade-up"
                >
                  <div className="video-container">
                    <iframe
                      width="100%"
                      height="200"
                      src={getEmbedUrl(item.videoUrl)}
                      title={`YouTube video ${index}`}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              ))}
            </div>
        </div>
        </section>
      <section className="ftco-section testimony-section" style={{
        paddingBottom:"0px"
      }}>
  <div className="container">
    <div className="row justify-content-center pb-3">
      <div className="col-md-7 heading-section text-center">
        <h2 className="mb-4">Our Founders & Executives</h2>
      </div>
    </div>
    </div>
    <div className="profiles">
  {founder.map((founders, index) => {
    return (
      <div className="profile-card" data-aos="fade-up" key={index}>
        <div className="profile-info">
          <div className="profile-image-container">
            <img
              src={founders.image}
              alt={founders.name}
              className="profile-image"
            />
            <div className="profile-details">
              <h3
                style={{
                  color: "white",
                  fontFamily: "'Lobster', cursive", // Unique font
                  letterSpacing: "1px", // Adds letter spacing
                  textShadow: "2px 2px 4px rgba(0,0,0,0.3)", // Adds text shadow
                }}
              >
                {founders.name}
              </h3>
              <h4
                style={{
                  color: "white",
                 
                 
                }}
              >
                {founders.role}
              </h4>
            </div>
          </div>
          <div className="profile-content">
            <ul>
              {founders.about.map((value, index) => {
                return (
                  <li
                    key={index}
                    style={{
                      color: "white",
                      marginTop: "5px",
                      fontFamily: "'Poppins', sans-serif",
                      fontSize: "15px",
                    }}
                  >
                    {value}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  })}
</div>

</section>
<Footer/>
<button to="" onClick={
        () => {
          window.scrollTo(0, 0);
        }
      } className="btn  btn-primary btn-lg-square rounded-circle back-to-top" ><i className="bi bi-arrow-up uop" style={{
      
        textAlign:"center"
      }}/></button>
    </Fragment>
  );
};

export default About;
