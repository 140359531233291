import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import client from "../../Common/Client/Client";
import "./course.css";
import Icons from "../../Common/Icons/Icons";
import Footer from "../../Common/Layout/Footer/Footer";
import Header from "../../Common/Layout/Header/Header";

const Course = () => {
  const navigate = useNavigate();
  const [course, setCourse] = useState([]);
  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = async () => {
    try {
      const response = await client.get("/course/getcourse");
      if (response.status === 200) {
        setCourse(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <Header />
      <Icons />
      <div>
        <div
          className="hero-wrap hero-wrap-2 "
          style={{
            backgroundImage: 'url("assets/images/bg_02.jpg")',
            backgroundAttachment: "fixed",
          }}
          data-aos="fade-up"
        >
          <div className="overlay" />
          <div className="container">
            <div
              className="row no-gutters slider-text align-items-center justify-content-center"
              data-scrollax-parent="true"
            >
              <div className="col-md-8  text-center">
                <p className="breadcrumbs">
                 
                </p>
                <h1 className="mb-3 "  style={{
                color:"#55230a",
               fontWeight:"bold",
                margin:"5px 0px 0px 20px"
              }}>Courses</h1>
              </div>
            </div>
          </div>
        </div>

        <section className="ftco-section" data-aos="fade-up">
          <div className="container">
            <div className="row justify-content-center mb-5 pb-3">
              <div className="col-md-7 heading-section   text-center">
                <h2 className="mb-4">Our Courses</h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row course_cards" style={{
              justifyContent:"center",
              rowGap:"30px"
            }}>
              {course.map((item, index) => {
                return (
                 
<div class="course_card">
  <div class="card__body">
    <p class="card__title">{item.courseName}</p>
    <p class="card__paragraph">
    {Array.isArray(item.aboutCourse)
  ? item.aboutCourse
      .join(" ")
      .slice(0, 170) + (item.aboutCourse.join(" ").length > 170 ? "..." : "")
  : item.aboutCourse.slice(0, 170) + (item.aboutCourse.length > 170 ? "..." : "")
}
    </p>
    <div class="button-container">
      <button onClick={() => { navigate("/course-details", { state: { courseDetails:item , targetId: "contact-details" } }); }} class="btn btn-primary">Enroll Now!</button>
      <button onClick={() => { navigate("/course-details", { state:  { courseDetails:item } }); }} class="btn btn-secondary">
        Learn More!
      </button>
    </div>
  </div>

  <div class="card__ribbon">
    <label class="card__ribbon-label">{index + 1}</label>
  </div>
</div>

                );
              })}
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <button
        to=""
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        className="btn  btn-primary btn-lg-square rounded-circle back-to-top"
      >
        <i
          className="bi bi-arrow-up uop"
          style={{
            textAlign: "center",
          }}
        />
      </button>
    </Fragment>
  );
};

export default Course;
