import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import client from "../../../Common/Client/Client";
import toast from "react-hot-toast";
import "./videos.css";
import Icons from "../../../Common/Icons/Icons";
import Header from "../../../Common/Layout/Header/Header";
import Footer from "../../../Common/Layout/Footer/Footer";

const Video = () => {
  const [url, setUrl] = useState([]);
  useEffect(() => {
    getlinks();
  }, []);

  const getlinks = async () => {
    try {
      const response = await client.get("/gallery/getyoutubeurl");
      if (response.status === 200) {
        setUrl(response.data);
      }
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  };
  const getEmbedUrl = (videoUrl) => {
    console.log(videoUrl);
    if (typeof videoUrl !== "string") {
      return ""; // Return an empty string if videoUrl is not a string
    }

    const videoIdMatch = videoUrl.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    if (videoIdMatch) {
      const videoId = videoIdMatch[1];
      return `https://www.youtube.com/embed/${videoId}`;
    }

    return ""; // Return an empty string if no video ID is found
  };
  return (
    <Fragment>
      <Header />
      <Icons />
      <div>
      <div
          className="hero-wrap hero-wrap-2 "
          style={{
            backgroundImage: 'url("assets/images/bg_002.jpg")',
            backgroundAttachment: "fixed",
          }}
          data-aos="fade-up"
        >
          <div className="overlay" />
          <div className="container">
            <div
              className="row no-gutters slider-text align-items-center justify-content-center"
              data-scrollax-parent="true"
            >
              <div className="col-md-8  text-center">
                <p className="breadcrumbs">
                 
                </p>
                <h1 className="mb-3 "  style={{
                color:"#55230a",
               fontWeight:"bold",
                margin:"5px 0px 0px 20px"
              }}>Videos</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="ftco-section bg-light" data-aos="fade-up">
          <div className="container">
            <div className="row justify-content-center mb-5 pb-3">
              <div className="col-md-7 heading-section  text-center">
                <h2 className="mb-4">Explore Our Videos</h2>
              </div>
            </div>
            <div className="row videos_row">
              {url.map((item, index) => (
                <div
                  key={index}
                  className="col-lg-4 mb-sm-4 "
                  data-aos="fade-up"
                >
                  <div className="video-container">
                    <iframe
                      width="100%"
                      height="200"
                      src={getEmbedUrl(item.videoUrl)}
                      title={`YouTube video ${index}`}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <button
        to=""
        onClick={() => {
          window.scrollTo(0, 0);
        }}
        className="btn  btn-primary btn-lg-square rounded-circle back-to-top"
      >
        <i
          className="bi bi-arrow-up uop"
          style={{
            textAlign: "center",
          }}
        />
      </button>
    </Fragment>
  );
};

export default Video;
