import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import client from "../../Client/Client";
import { FaTiktok } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import logo from "../../../../assets/images/logo.jpeg";

const Footer = () => {
  const [course, setCourse] = useState([]);
  const [contact, setContact] = useState({
    phoneIndia: "",
    phoneUAE: "",
    whatsapp: "",
    email: "",
    address: "",
    youtube: "",
    facebook: "",
    tiktok: "",
    instagram: "",
  });
  useEffect(() => {
    fetchCourse();
    fecthContact();
  }, []);

  const fecthContact = async () => {
    try {
      const response = await client.get("/contact/get");
      if (response.status === 200) {
        const data = response.data[0];
        setContact((pre) => {
          return {
            ...pre,
            address: data.address,
            phoneIndia: data.phoneIndia,
            phoneUAE: data.phoneUAE,
            whatsapp: data.whatsapp,
            email: data.email,
            youtube: data.youtube,
            facebook: data.facebook,
            tiktok: data.tiktok,
            instagram: data.instagram,
          };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCourse = async () => {
    try {
      const response = await client.get("/course/getcourse");
      if (response.status === 200) {
        setCourse(response.data.slice(0,6));
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Fragment>
      <footer
        className="ftco-footer bg-bottom ftco-no-pt "
        style={{ backgroundImage: "url(assets/images/foot.jpg)", zIndex: "10",paddingBottom:"100px" }}
      >
        <div className="container">
          <div className="row footer-lay">
            <div className="col-md">
              <div className="ftco-footer-widget pt-md-5 " data-aos="fade-up">
                <Link className="navbar-brand" to="/">
                  <img src={logo} alt="logo" width="60px" height="60px" />
                </Link>
                <h2 className="ftco-heading-2 custom-headings">Dream Live</h2>
                <p style={{ textAlign: "left" }}>
                  Welcome to DREAM LIVE, where we provide expert OET training
                  for healthcare professionals, combining personalized
                  instruction with practical practice for exam success.
                </p>
                <ul
                  class="wrapper"
                  style={{
                    marginTop: "20px",
                  }}
                >
                  <li
                    class="icon facebook"
                    onClick={() => {
                      window.open(contact.facebook, "_blank");
                    }}
                  >
                    <span class="tooltip">Facebook</span>
                    <svg
                      viewBox="0 0 320 512"
                      height="1.2em"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                    </svg>
                  </li>
                  <li
                    class="icon youtube"
                    onClick={() => {
                      window.open(contact.youtube, "_blank");
                    }}
                  >
                    <span class="tooltip">YouTube</span>
                    <svg
                      viewBox="0 0 24 24"
                      height="1.2em" /* Adjust height as needed */
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clip-rule="evenodd"
                        d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  </li>

                  <li
                    class="icon instagram"
                    onClick={() => {
                      window.open(contact.instagram, "_blank");
                    }}
                  >
                    <span class="tooltip">Instagram</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1.2em"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
                    </svg>
                  </li>
                  <li
                    class="icon tiktok"
                    onClick={() => {
                      window.open(contact.tiktok, "_blank");
                    }}
                  >
                    <span class="tooltip">TikTok</span>
                    <FaTiktok />
                  </li>
                  <li
                    class="icon whatsapp"
                    onClick={() => {
                      const formattedNumber = contact.whatsapp.replace(
                        /[^0-9]/g,
                        ""
                      ); // Removes all non-numeric characters
                      window.open(`https://wa.me/${formattedNumber}`, "_blank"); // Opens in a new tab
                    }}
                  >
                    <span class="tooltip">Whatsapp</span>
                    <FaWhatsapp />
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md  border-left">
              <div
                className="ftco-footer-widget pt-md-5 mb-4 ml-md-5  footer-pages"
                data-aos="fade-up"
              >
                <h2
                  className="ftco-heading-2 custom-headings"
                  data-aos="fade-up"
                >
                  Pages
                </h2>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/" className="py-2 d-block">
                      <i
                        className="fas fa-chevron-right"
                        style={{
                          fontSize: "12px",
                          color: "#007bff",
                          marginRight: "10px",
                        }}
                      ></i>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" className="py-2 d-block">
                      <i
                        className="fas fa-chevron-right"
                        style={{
                          fontSize: "12px",
                          color: "#007bff",
                          marginRight: "10px",
                        }}
                      ></i>
                      About
                    </Link>
                  </li>
                  <li>
                    <Link to="/course" className="py-2 d-block">
                      <i
                        className="fas fa-chevron-right"
                        style={{
                          fontSize: "12px",
                          color: "#007bff",
                          marginRight: "10px",
                        }}
                      ></i>
                      Course
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog" className="py-2 d-block">
                      <i
                        className="fas fa-chevron-right"
                        style={{
                          fontSize: "12px",
                          color: "#007bff",
                          marginRight: "10px",
                        }}
                      ></i>
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/testimonals" className="py-2 d-block">
                      <i
                        className="fas fa-chevron-right"
                        style={{
                          fontSize: "12px",
                          color: "#007bff",
                          marginRight: "10px",
                        }}
                      ></i>
                      Testimonals
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="py-2 d-block">
                      <i
                        className="fas fa-chevron-right"
                        style={{
                          fontSize: "12px",
                          color: "#007bff",
                          marginRight: "10px",
                        }}
                      ></i>
                      Contact us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md  border-left">
              <div
                className="ftco-footer-widget pt-md-5 mb-4 ml-md-5 footer-pages"
                data-aos="fade-up"
              >
                <h2
                  className="ftco-heading-2 custom-headings"
                  data-aos="fade-up"
                >
                  Courses
                </h2>
                <ul className="list-unstyled">
                  {course.map((item, index) => {
                    return (
                      <li>
                        <Link to="/course" className="py-2 d-block">
                          <i
                            className="fas fa-chevron-right"
                            style={{
                              fontSize: "12px",
                              color: "#007bff",
                              marginRight: "10px",
                            }}
                          ></i>
                          {item.courseName}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md border-left get-in-touch">
              <div
                className="ftco-footer-widget pt-md-5 mb-4"
                data-aos="fade-up"
              >
                <h2
                  className="ftco-heading-2 custom-headings mb-3"
                  data-aos="fade-up"
                >
                  Get in Touch
                </h2>
                <div className="block-23 mb-3">
                  <ul>
                    <li>
                      <span
                        className="icon fa fa-map-marker"
                        style={{
                          paddingRight: "20px",
                        }}
                      />
                      <span
                        style={{
                          textAlign: "left",
                        }}
                        className="text"
                      >
                        {contact.address}
                      </span>
                    </li>
                    <li>
                      <Link to="">
                        <span
                          className="icon fas fa-phone"
                          onClick={() => {
                            window.location.href = `tel: ${contact.phoneUAE}`;
                          }}
                        />
                        <span className="text">{contact.phoneUAE}(UAE)</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span className="icon fas fa-phone" />
                        <span
                          className="text"
                          onClick={() => {
                            window.location.href = `tel: ${contact.phoneIndia}`;
                          }}
                        >
                          {contact.phoneIndia}(India)
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <i className="icon fa fa-envelope" />
                        <span
                          className="text"
                          onClick={() => {
                            window.location.href = `mailto:${contact.email}`;
                          }}
                        >
                          {contact.email}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
