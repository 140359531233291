import About from "../../Pages/About/About";
import Blog from "../../Pages/Blog/Blog";
import BlogDetails from "../../Pages/Blog/BlogDetails";
import Contact from "../../Pages/Contact/Contact";
import Course from "../../Pages/Courses/Course";
import CourseDetails from "../../Pages/Courses/CourseDetails";
import Image from "../../Pages/Gallery/Images/Image";
import Video from "../../Pages/Gallery/Videos/Video";
import Home from "../../Pages/Home/Home";
import Testimonal from "../../Pages/Testimonals/Testimonal";
import NotFound from "../NotFound/NotFound";

const route = [
  { path: "/", elements: <Home /> },
  { path: "/about", elements: <About /> },
  { path: "/course", elements: <Course /> },
  {path:"/course-details",elements:<CourseDetails/>},
  { path: "/blog", elements: <Blog /> },
  { path: "/testimonals", elements: <Testimonal /> },
  { path: "/contact", elements: <Contact /> },
  { path: "/images", elements: <Image /> },
  { path: "/videos", elements: <Video /> },
  {path:"/blog-details",elements:<BlogDetails/>},
  {path:"*",elements:<NotFound/>}

];

export default route;
