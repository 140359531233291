import React, { Fragment, useEffect, useState } from "react";
import Icons from "../../Common/Icons/Icons";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./BlogDetails.css";
import client from "../../Common/Client/Client";
import toast from "react-hot-toast";
import { FaFeatherPointed } from "react-icons/fa6";
import Header from "../../Common/Layout/Header/Header";
import Footer from "../../Common/Layout/Footer/Footer";
const BlogDetails = () => {
  const location = useLocation();
  const blogDetail = location.state;
  const [blogDetails,setBlogDetails]=useState(blogDetail)
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    getBlogs();
  }, []);

  console.log(blogDetails)

  const getBlogs = async () => {
    try {
      const response = await client.get("/blog/get");
      if (response.status === 200) {
        const blogs = response.data;
        setBlogs(blogs.slice(-3));
      } else {
        toast.error("Error occuring in fetching data");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Formats date as dd/mm/yyyy
  };

  const truncateDescription = (desc) => {
    // Ensure desc is an array and convert to a single string
    const fullText = Array.isArray(desc) ? desc.join(" ") : desc;
    return fullText.split(" ").slice(0, 20).join(" ") + "...";
  };
  return (
    <Fragment>
      <Header/>
      <Icons />
      <div
          className="hero-wrap hero-wrap-2 "
          style={{
            backgroundImage: 'url("assets/images/bg_0002.jpg")',
            backgroundAttachment: "fixed",
            backgroundPosition:"Top"
          }}
          data-aos="fade-up"
        >
          <div className="overlay" />
          <div className="container">
            <div
              className="row no-gutters slider-text align-items-center justify-content-center"
              data-scrollax-parent="true"
            >
              <div className="col-md-8  text-center">
                <p className="breadcrumbs">
                 
                </p>
                <h1 className="mb-3 "  style={{
                color:"#55230a",
               fontWeight:"bold",
                margin:"5px 0px 0px 20px"
              }}>Blog <br /> Details</h1>
              </div>
            </div>
          </div>
        </div>
    { blogDetail  && 
     <div className="blogdetails-container" data-aos="fade-up">
  <div className="row">
    <div className="col-md-8 yes">
      <div className="blogdetails-content">
        <div className="blogdetails-image">
          <img
            src={blogDetails.image}
            alt={blogDetails.name}
            className="blogdetails-image"
          />
        </div>
        <div className="blogdetails-text">
          <h2 className="blogdetails-title">{blogDetails.name}</h2>
          <p className="blogdetails-date-location">
            <span className="blogdetails-date">
              {formatDate(blogDetails.date)}
            </span>{" "}
            |{" "}
            <span className="blogdetails-location">
              {blogDetails.location}
            </span>
          </p>
          <div className="blogdetails-description">
            {blogDetails.description.map((text, index) => (
              <p key={index} data-aos="fade-up" style={{
                textAlign:"left",
                fontSize:"16px",
                color:"#333",
                marginTop:"10px"
              }}><FaFeatherPointed style={{
                fontSize:"16px",
                color:"blue",
                marginRight:"5px"
              }} />{text}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="col-md-4 no" data-aos="fade-up">
      <div className="sidebar">
        <h3 className="sidebar-title" style={{
          color:"#007bff"
        }}>Recent Blogs</h3>
        {blogs.map((blog) => (
          <div className="sidebar-blog" key={blog.id} data-aos="fade-up">
            <img
              src={blog.image}
              alt={blog.name}
              className="sidebar-blog-image"
            />
            <div className="sidebar-blog-content">
              <h4>{blog.name}</h4>
           
              <Link to='/blog' className="sidebar-blog-link">
                Read More
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
  </div>
  }

<Footer/>
<button to="" onClick={
        () => {
          window.scrollTo(0, 0);
        }
      } className="btn  btn-primary btn-lg-square rounded-circle back-to-top" ><i className="bi bi-arrow-up uop" style={{
      
        textAlign:"center"
      }}/></button>
    </Fragment>
  );
};

export default BlogDetails;
