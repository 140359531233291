import React, { useEffect, useState,Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import client from "../../Common/Client/Client";
import toast from "react-hot-toast";
import './Blog.css'
import Icons from "../../Common/Icons/Icons";
import Header from "../../Common/Layout/Header/Header";
import Footer from "../../Common/Layout/Footer/Footer";

const Blog = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate=useNavigate()
  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    try {
      const response = await client.get("/blog/get");
      if (response.status === 200) {
        setBlogs(response.data);
      } else {
        toast.error("Error occuring in fetching data");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const truncateDescription = (desc) => {
    const fullText = desc.join(' ');
    return fullText.split(' ').slice(0, 20).join(' ') + '...';
  };

  const convertDateFormat = (dateString) => {
    const [year, month, day] = dateString.split('-');
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  
 
  return (
    <Fragment>
      <Header/>
      <Icons/>
  <div>

  <div className="hero-wrap hero-wrap-2 blog_top_image" data-aos="fade-up" style={{backgroundImage: 'url("assets/images/bg_0002.jpg")', backgroundAttachment: 'fixed'}}>
    <div className="overlay" />
    <div className="container">
      <div className="row no-gutters slider-text align-items-center justify-content-center" data-scrollax-parent="true">
        <div className="col-md-8  text-center">
          <p className="breadcrumbs"></p>
          <h1 className="mb-3 "  style={{
                color:"#55230a",
               fontWeight:"bold",
                margin:"5px 0px 0px 20px"
              }}>Blog</h1>
        </div>
      </div>
    </div>
  </div>
  <section className="ftco-section" data-aos="fade-up">
    <div className="container">   
      <div className="row justify-content-center">
    <div className="col-md-7 heading-section  text-center">
                <h2 className="mb-4">Our Insights
                </h2>
              </div>
    </div>

    </div>
    <div className="rowss">
  {blogs.map((blog, index) => {
    return (
      <div className="blog-card" key={index} data-aos="fade-up" >
        {/* Blog Image */}
        <div className="blog-image-container">
          <img src={blog.image} alt={blog.name} className="blog-image" />
        </div>

        {/* Blog Details */}
        <div className="blog-details">
          <h2 className="blog-title">{blog.name}</h2>
          <p className="blog-meta">By Dream Live | {convertDateFormat(blog.date)}</p>
          <p className="blog-description">{truncateDescription(blog.description)}</p>
          <p className="read-more-button" onClick={()=>{
            navigate("/blog-details",{
              state:blog
            })

          }}>Read More</p>
        </div>
      </div>
    );
  })}
</div>
    </section>

 
     
   

</div>

<Footer/>
<button to="" onClick={
        () => {
          window.scrollTo(0, 0);
        }
      } className="btn  btn-primary btn-lg-square rounded-circle back-to-top" ><i className="bi bi-arrow-up uop" style={{
      
        textAlign:"center"
      }}/></button>
    </Fragment>
  );
};

export default Blog;
