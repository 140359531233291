import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import route from "./Components/Common/Route/Route";

import { Toaster } from "react-hot-toast";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollToTop from "./Components/Common/Layout/ScrollTop/Scrool";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    AOS.init({  duration: 1200});
  }, [])
  return (
    <div className="App">
      <BrowserRouter>
      <Toaster
        toastOptions={{
          success: {
            style: {
              duration: 3000,
              background: "green",
              color: "white",
            },
          },
          error: {
            style: {
              duration: 3000,
              background: "red",
              color: "white",
            },
          },
        }}
      />
        <ScrollToTop/>
        <Routes>
          {route.map((value, index) => {
            return (
              <Route key={index} path={value.path} element={value.elements} />
            );
          })}
        </Routes>
      </BrowserRouter>
    

    </div>
  );
}

export default App;
