import React, { Fragment,useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import client from '../../../Common/Client/Client'
import Icons from '../../../Common/Icons/Icons'
import Footer from '../../../Common/Layout/Footer/Footer'
import Header from '../../../Common/Layout/Header/Header'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';


const Image = () => {
  const [image,setImage]=useState([])
  useEffect(()=>{
  getImage()
  },[])
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleImageClick = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };
  
  const getImage=async()=>{
    try{
      const response = await client("/imagegallery/getimage")
      if(response.status===200){
        setImage(response.data)
      }
    }catch(err){
      console.log(err)
    }
  }
  return (
    <Fragment>
      <Header/>
      <Icons/>
        <div>
        <div
          className="hero-wrap hero-wrap-2 "
          style={{
            backgroundImage: 'url("assets/images/bg_002.jpg")',
            backgroundAttachment: "fixed",
          }}
          data-aos="fade-up"
        >
          <div className="overlay" />
          <div className="container">
            <div
              className="row no-gutters slider-text align-items-center justify-content-center"
              data-scrollax-parent="true"
            >
              <div className="col-md-8  text-center">
                <p className="breadcrumbs">
                 
                </p>
                <h1 className="mb-3 "  style={{
                color:"#55230a",
               fontWeight:"bold",
                margin:"5px 0px 0px 20px"
              }}>Images</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="ftco-section bg-light" data-aos="fade-up">
          <div className="container">
            <div className="row justify-content-center mb-5 pb-3">
              <div className="col-md-7 heading-section  text-center">
                <h2 className="mb-4">Gallery of Memories</h2>
              </div>
            </div>
            <div style={{
              width:"100%"
            }}>
            <ImageList sx={{ width: 500, height: 450 }} variant="woven" cols={3} gap={8}>
      {image.map((item,index) => (
        <ImageListItem key={item.image} className='image-item'>
          <img
            srcSet={`${item.image}?w=161&fit=crop&auto=format&dpr=2 2x`}
            src={`${item.image}?w=161&fit=crop&auto=format`}
            alt=""
            loading="lazy"
             onClick={() => handleImageClick(index)}  // Open lightbox on click
              style={{ cursor: "pointer" }}
          />
        </ImageListItem>
      ))}
    </ImageList>
    {isOpen && (
        <Lightbox
          open={isOpen}
          index={currentIndex}
          close={() => setIsOpen(false)}
          slides={image.map(item => ({ src: item.image }))}
        />
      )}
    </div>
           
          </div>
        </section>
    
      </div>
      <Footer/>
      <button to="" onClick={
        () => {
          window.scrollTo(0, 0);
        }
      } className="btn  btn-primary btn-lg-square rounded-circle back-to-top" ><i className="bi bi-arrow-up uop" style={{
      
        textAlign:"center"
      }}/></button>
    </Fragment>
  )
}

export default Image